/* web */
* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  /* font-family: 'Arial Black', arial, sans-serif; */
  font-family: 'Arial Black', 'Archivo Black', sans-serif;
  font-weight: 900;
}

body {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header-top {
  position: absolute;
  top: calc(100vh / 3);
  width: 51rem;
  display: flex;
  justify-content: flex-end;
  z-index: 999;
  border-bottom: solid 3px #fff;
}

.vaporwave-header-text {
  color: #fff;
  font-size: 5rem;
  margin: 0;
}
.nav-container {
  width: 100%;
}

.nav-ul {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 2rem;
  /* top: 4rem; */
  right: 20vw;
  color: #fff;
  margin: 0;
  list-style: none;
  z-index: 999;
}
.nav-ul li {
  margin-right: 2rem;
}
.nav-ul li:last-child {
  margin: 0;
}

.nav-ul li:hover {
  color: #2699fb;
}

.category-name-container {
  position: absolute;
  left: 0;
  top: calc((100vh / 3) + 6rem);
  width: 51rem;
}
.category-name {
  color: #fff;
  font-size: 3.75rem;
  font-weight: 900;
  text-align: right;
}

.img-container {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-container img {
  min-height: 100vh;
  min-width: 100vw;
  z-index: -1;
  position: relative;
  filter: brightness(50%);
}

.category-desc {
  position: absolute;
  bottom: 5rem;
  z-index: 999;
  width: 100%;
  text-align: center;
}

.category-desc p {
  color: #fff;
}

footer {
  margin-top: auto;
  text-align: center;
}

.bottom-container {
  background-color: #3e3e3e;
  height: 100vh;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.biz-info-i {
  color: #fff;
  text-align: left;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.biz-info-title {
  color: #fff;
  text-align: left;
}

.biz-info-text,
.biz-info-text > * {
  color: #707070;
  text-align: left;
  display: block;
}

.logo-footer-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo-footer {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
}
.logo-footer-vw {
  margin: 0;
  font-size: 6rem;
  line-height: 0.75;
  color: #fff;
}

.logo-footer-ss {
  margin: 0;
  display: flex;
  justify-content: end;
  color: #707070;
  font-size: 3.5rem;
}

.contact-number:hover,
.biz-address:hover,
.biz-contact-email:hover {
  color: #2699fb;
}

.business-info {
  /* height: 50vh; */
  /* background-color: #2699fb; */
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  justify-content: space-around;
  align-items: start;
  width: 100%;
}

.biz-info-offer,
.biz-contact,
.biz-hours {
  display: flex;
  flex-direction: column;
}
.biz-address,
.biz-hours,
.biz-contact-email,
.contact-number {
  text-decoration: none;
  /* display: flex;
  flex-direction: column; */

  /* font-size: 2.75rem; */
  /* color: #fff; */
}

.social-media-container {
  margin-bottom: 3vh;
}

.social-media-title {
  color: #fff;
  font-size: 1.5rem;
}

.social-media {
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;
  padding: 0 20vw;
  margin-bottom: 6rem;
}

.social-media > *:not(:last-child) {
  margin-right: 2rem;
}

.social-media a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  font-size: 2.25rem;
  color: #fff;
  background-color: #707070;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;

  .social-i:hover {
    color: #2699fb;
  }
}
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/* mobile */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
@media (pointer: coarse) {
  .biz-info-i,
  .nav-ul {
    display: none;
  }

  header {
    width: 100%;
  }
  .header-top {
    border-bottom: solid 0.4vh #fff;
    left: 0;
    text-align: right;
    top: calc(100vh / 5);
    width: 75vw;
  }
  .header-top h3 {
    font-size: 8.5vw;
    margin: 0;
  }

  .vaporwave-header-text {
    align-self: center;
    all: unset;
    color: #ffff;
    font-size: 2.25rem;
  }

  .carousel-indicators {
    margin-bottom: 12vh;
  }

  .carousel-indicators [data-bs-target] {
    height: 4vw;
    width: 4vw;
    border-radius: 100%;
    margin-left: 4vw;
    margin-right: 1vw;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    /* height: 10vw;
    width: 10vw;
    max-height: 5rem; */
    display: none;
  }

  .categories {
    overflow: visible;
    width: 100vw;
  }
  .category {
    border-bottom: 1px solid #f0f0f0;
    position: relative;
    text-align: center;
  }

  .img-container img {
    min-width: initial;
    min-height: initial;
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }

  .category-name-container {
    left: 0;
    text-align: right;
    top: calc((100vh / 5) + 14vw);
    width: 75vw;
  }
  .category-name {
    color: #ffff;
    font-size: 6.375vw;
  }

  .category-desc {
    bottom: 20vh;
    display: flex;
    justify-content: center;
    font-size: 2vh;
    padding: 1rem 0;
    width: 100%;
  }

  .category-desc p {
    width: 80vw;
  }

  .carousel-dot {
    height: 1rem;
    border-radius: 50%;
    width: 1rem;
  }

  footer {
    height: auto;
  }
  .bottom-container {
    height: auto;
  }
  .business-info {
    flex-direction: column;
    display: flex;
    justify-content: center;
  }

  .logo-footer-container {
    margin-bottom: 5vh;
  }

  .logo-footer-vw {
    font-size: 6vw;
  }

  .logo-footer-ss {
    font-size: 4.5vw;
  }

  .biz-info-title,
  .biz-info-text,
  .biz-info-i {
    width: 100%;
    text-align: center;
  }

  .biz-info-offer,
  .biz-contact,
  .biz-hours {
    /* margin: 4vh auto; */
    margin: calc(2vh + 2vw) auto;
    font-size: 3.5vw;
  }

  .social-media {
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: center;
    height: 8vh;
    margin-bottom: 3vh;
    padding-left: 36px;
    padding-right: 36px;
    text-align: center;
  }

  .social-media-title {
    margin: 1.5vh auto;
    font-size: 3.5vw;
    text-align: center;
  }

  .social-media a {
    height: calc(3.5vh + 3vw);
    margin: 0 auto;
    text-decoration: none;
    width: calc(3.5vh + 3vw);
  }

  .social-i {
    color: #fff;
    font-size: calc(1.5vh + 3vw);
  }
}
